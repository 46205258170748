<template>
	<div class="pt-6 pb-4 px-4">
		<div class="flex flex-wrap justify-between items-center">
			<div class="flex-1 flex justify-between items-start gap-2">
				<!-- ------------------------------------------------- -->
				<!-- HEADER -->
				<!-- ------------------------------------------------- -->
				<div class="space-y-2">
					<div class="flex items-center gap-2">
						<!-- ------------------------------------------------- -->
						<!-- (SUPER)-TITLE -->
						<!-- ------------------------------------------------- -->
						<div>
							<div v-if="stage.hero.supertitle" class="flex items-center gap-2">
								<p class="text-sm">{{ stage.hero.supertitle }}</p>
								<portal-target :name="`stage-${index}-supertitle-menu`"></portal-target>
							</div>

							<p class="text-3xl font-bold">{{ stage.hero.title }}</p>
						</div>

						<div>
							<portal-target :name="`stage-${index}-menu`"></portal-target>
						</div>

						<!-- ------------------------------------------------- -->
						<!-- MENU -->
						<!-- ------------------------------------------------- -->
						<portal :to="stage.hero.supertitle ? `stage-${index}-supertitle-menu` : `stage-${index}-menu`">
							<div class="flex items-center gap-2">
								<button
									v-for="(btn, index) in stage.hero.buttons?.items?.filter(
										(i) => i.condition == null || i.condition == true
									)"
									:key="index"
									class="d-btn"
									:class="btn.class"
									@click="
										() => {
											if (btn.disabled) return
											btn.click()
										}
									"
								>
									<icon :icon="btn.icon" :cls="[btn.iconClass]"></icon>
								</button>

								<tippy
									v-if="stage.hero.menu && stage.hero.menu?.items?.length"
									:ref="
										(el) => {
											refTippy = el
										}
									"
								>
									<template #trigger>
										<button class="d-btn d-btn-xs d-btn-circle">
											<icon icon="dots-horizontal" cls="w-4 h-4 text-primary"></icon>
										</button>
									</template>
									<ul class="min-w-52 d-menu w-full rounded-box">
										<li
											v-for="(item, index) in stage.hero.menu.items.filter(
												(i) => i.condition == null || i.condition == true
											)"
											:key="index"
											:class="{ 'd-disabled': item.disabled }"
										>
											<a
												@click="
													() => {
														refTippy.tip.hide()
														if (item.disabled) return
														item.link()
													}
												"
											>
												<icon :icon="item.icon" :cls="item.iconClass">{{ item.label }}</icon>
											</a>
										</li>
									</ul>
								</tippy>
							</div>
						</portal>
					</div>

					<!-- ------------------------------------------------- -->
					<!-- SUBTITLE & DESCRIPTION -->
					<!-- ------------------------------------------------- -->
					<p v-if="stage.hero.subtitle" :class="stage.hero.subtitleClass">{{ stage.hero.subtitle }}</p>
					<p class="text-sm" :class="stage.hero.descriptionClass" v-if="stage.hero.description">
						{{ stage.hero.description }}
					</p>

					<!-- ------------------------------------------------- -->
					<!-- BADGES-->
					<!-- ------------------------------------------------- -->
					<div class="flex items-center gap-2">
						<span
							class="d-badge"
							:class="badge.class"
							v-for="(badge, index) in stage.hero.badges?.filter((b) => b.condition == null || b.condition == true)"
							:key="index"
						>
							<template v-if="badge.icon"><icon :icon="badge.icon"></icon></template>
							<template v-else>{{ badge.name }}</template>
						</span>
					</div>
				</div>

				<!-- ------------------------------------------------- -->
				<!-- CLOSE -->
				<!-- ------------------------------------------------- -->
				<button
					v-if="stage.options?.closable"
					class="d-btn d-btn-sm d-btn-ghost d-btn-circle"
					@click="stage.options?.on?.close()"
				>
					<icon icon="close"></icon>
				</button>
			</div>

			<!-- ------------------------------------------------- -->
			<!-- PORTAL -->
			<!-- ------------------------------------------------- -->
			<portal-target :name="`stage-${index}-hero`" multiple></portal-target>
		</div>
	</div>
</template>

<script setup>
import { ref, inject } from 'vue'

const props = defineProps({
	stage: {
		type: Object,
	},
	index: {
		type: Number,
	},
})

const rysqer = inject('rysqer')
</script>
