<template>
	<div>
		<Vueform
			:size="size"
			ref="refForm"
			v-bind="localDefinition"
			v-model="form"
			sync
			:disabled="disabled"
			:endpoint="false"
			@change="$emit('input', form)"
			@submit="$emit('submit', form)"
			:columns="{ container: container }"
			:add-class="[gutterClass]"
		/>
	</div>
</template>

<script setup>
import { ref, watch, computed, inject } from 'vue'

const props = defineProps({
	definition: {
		type: Object,
	},
	value: {
		type: Object,
	},
	disabled: {
		type: Boolean,
	},
	container: {
		type: Number,
		default: 12,
	},
	size: {
		type: String,
		default: 'md',
	},
	gutter: {
		type: String,
		default: null,
	},
})

const emit = defineEmits(['change', 'submit', 'input'])

const rysqer = inject('rysqer')

const refForm = ref(null)

const form = ref({})

const localDefinition = ref()

const gutterClass = computed(() => {
	if (props.gutter == 'narrow') {
		return 'vf-gutter-narrow'
	}

	return null
})
const redefine = () => {
	let tmpDef = { ...props.definition }

	const transformObject = (obj) => {
		for (const key in obj) {
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				transformObject(obj[key])
			}

			if (obj.hasOwnProperty('type')) {
				switch (obj.type) {
					case 'boolean':
						Object.assign(obj, {
							type: 'radiogroup',
							view: 'tabs',
							items: [
								{ value: true, label: 'Yes' },
								{ value: false, label: 'No' },
							],
							columns: { wrapper: 6, container: 12, label: 12 },
						})
						break

					case 'select':
						Object.assign(obj, {
							native: false,
							search: true,
							inputType: 'search',
							autocomplete: 'off',
						})
						break

					case 'multiselect':
						Object.assign(obj, {
							type: 'tags',
							closeOnSelect: false,
							search: true,
							inputType: 'search',
							autocomplete: 'off',
						})
						break

					case 'number':
						if (obj.prefix) {
							setNumberWithPrefix(obj, '€')
						} else {
							Object.assign(obj, {
								type: 'text',
								inputType: 'number',
								rules: ['numeric'],
							})
						}
						break

					case 'year':
						Object.assign(obj, {
							type: 'text',
							inputType: 'number',
							rules: ['digits:4', 'integer'],
						})
						break

					case 'daterange':
						Object.assign(obj, {
							type: 'dates',
							mode: 'range',
						})
						break

					case 'monetary':
						setNumberWithPrefix(obj, '€')
						break

					case 'percentage':
						setNumberWithPrefix(obj, '%')
						break
				}
			}

			// if (key === 'rules' && Array.isArray(obj[key])) {
			// 	// Manage "rules" array: ensure "required" is added if "optional" exists
			// 	obj[key] = obj[key].filter((rule) => rule !== 'optional')
			// 	if (!obj[key].includes('required')) obj[key].push('required')
			// } else if (key === 'conditions' && Array.isArray(obj[key]) === false) {
			// 	obj[key] = [obj[key]] // Wrap "conditions" in an array if not already
			// }

			// if (obj.hasOwnProperty('schema') && typeof obj.schema === 'object') {
			// 	for (const schemaKey in obj.schema) {
			// 		if (typeof obj.schema[schemaKey] === 'object') {
			// 			obj.schema[schemaKey].addClass = 'bg-base-200 p-2'
			// 		}
			// 	}
			// }
		}
	}

	const setNumberWithPrefix = (obj, prefix) => {
		Object.assign(obj, {
			type: 'text',
			inputType: 'number',
			autocomplete: 'off',
			addons: { before: prefix },
		})
	}

	transformObject(tmpDef)

	localDefinition.value = { schema: tmpDef }
}

watch(
	() => props.value,
	() => {
		if (refForm.value) refForm.value.resetValidators()
		if (props.value) form.value = { ...props.value }
	},
	{ immediate: true }
)

watch(
	() => props.definition,
	() => {
		if (props.definition) redefine()
	},
	{ immediate: true }
)

defineExpose({ refForm, form })
</script>

<style lang="scss">
.vf-gutter-narrow *,
.vf-gutter-narrow *:before,
.vf-gutter-narrow *:after,
.vf-gutter-narrow:root {
	--vf-gutter: 12px;
	--vf-font-size: 16px;
}
</style>
